import React from "react"
import loadable from "@loadable/component"
import "./homepage.scss"

const SlideSection = loadable(() => import("./SlideSection"))
const ServiceSection = loadable(() => import("./Services"))
const Process = loadable(() => import("./Process"))
const Banner = loadable(() => import("../Banner/Banner"))

const BannerImg = `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/HomePage/home_page_slider.webp`
const bannerDataDesk = {
  title: ["Revolutionize Your Business", "With Game-changing AI"],
}
const bannerDataMobile = {
  titleH2: ["Revolutionize  ", "Your Business With ", "Game-changing AI"],
}

const HomePage = () => {
  return (
    <React.Fragment>
      <section className="homepage">
        <section className="homepage__bannerOnDesk">
          <Banner item={bannerDataDesk} backgroundImage={BannerImg} />
        </section>
        <section className="homepage__bannerOnMobile">
          <Banner item={bannerDataMobile} backgroundImage={BannerImg} />
        </section>
        <SlideSection />
        <ServiceSection />
        <Process />
      </section>
    </React.Fragment>
  )
}

export default HomePage
